import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Image from '../../../components/Image';

const KatieLampo = () => (
  <Layout title="Alumni Spotlight - Katie Lampo" className="alumni-center bios">
    {/* Alumni Profile */}
    <Section className="alumni">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../overview">Overview</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../alumni-spotlight">Alumni Spotlight</Link>
          </li>
          <li className="breadcrumb active">Kathryn Lampo</li>
        </ul>
        <Row>
          <Column size={7} offset={1} className="alumni__profile-left pr-2">
            <h2 className="alumni__profile-greeting">Meet Alumni:</h2>
            <h1 className="alumni__profile-title">Kathryn Lampo</h1>
            <h3 className="alumni__profile-type">
              2017 Young Scientist Challenge Finalist
            </h3>
            <h4 className="alumni__profile-project">
              Kathryn is currently a sophomore at Columbia University pursuing a
              B.S. in Mechanical Engineering with a minor in Applied Physics.
            </h4>
            <h2 className="alumni__profile-question">
              What have you been up to since participating in the 3M Young
              Scientist Challenge?
            </h2>
            <p>
              Since I was a finalist in 2017, I’ve graduated from high school
              and moved from my hometown in Colorado to New York for college.
              Even though my challenge entry was focused on sustainable
              engineering and robotics, I’ve continued to develop other
              interests in aerospace engineering and astrophysics, which I
              pursue here at school. Outside of my studies, I’ve also spent the
              past two summers as a space intern, and I plan to study abroad in
              Madrid this coming spring semester.
            </p>
            <h2 className="alumni__profile-question">
              What are your plans for the future?
            </h2>
            <p>
              Following the completion of my bachelor's degree, I hope to attend
              graduate school to pursue an advanced degree in an aerospace or
              astrophysics related field. What happens after that remains to be
              seen!
            </p>
            <h2 className="alumni__profile-question">
              What is your favorite innovation of the last 100 years, and why?
            </h2>
            <p>
              My favorite innovation of the last 100 years is the atomic clock—a
              timekeeping device that relies on electron energy states in atoms
              to tell time with extraordinary accuracy. It’s been fundamental to
              many major technological advancements since its creation in 1948,
              including GPS and cell phones. It has also contributed heavily to
              developments in aerospace and discoveries in physics, and
              establishes the global standard for timekeeping.
            </p>
            <h2 className="alumni__profile-question">
              Describe your relationship with your 3M Mentor.
            </h2>
            <p>
              Working with{' '}
              <Link to="/annual-challenge/finalists-mentors-judges/mentors/mary-caruso-dailey">
                Dr. Daily
              </Link>{' '}
              was easily one of the highlights of my 3M Young Scientist
              Challenge experience—the knowledge and experience she shared was
              invaluable, and she helped me not only develop my innovation, but
              develop my skills in regard to the engineering design process as
              well.
            </p>
            <h2 className="alumni__profile-question">
              What advice do you have for a student who is considering entering
              the 3M Young Scientist Challenge? How would you say the 3M Young
              Scientist Challenge has impacted your life?
            </h2>
            <p>
              My best advice is to just go for it! I almost didn’t submit my
              entry video because I felt like there were still too many flaws in
              my design to present it to the judges. Remember that science and
              engineering are iterative processes, and it’s okay if your work
              isn’t perfect! I’m so glad I did decide to hit that submit button,
              because the 3M Young Scientist Challenge turned out to be a
              perfect opportunity to develop not only my technical skills, but
              also to develop confidence in my STEM abilities, encouraging me to
              continue to pursue even the loftiest of my goals. If you’re
              considering it, give it a shot after all, you never know what
              might happen!
            </p>
          </Column>
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="global__profile-image">
                <Image filename="Katie-Lampo-Alumni-Quote.png" />
              </div>
              <div className="global__profile-content">
                <h4 className="quote">
                  “I’m so glad I did decide to hit that submit button, because
                  the 3M Young Scientist Challenge turned out to be a perfect
                  opportunity to develop not only my technical skills, but also
                  to develop confidence in my STEM abilities, encouraging me to
                  continue to pursue even the loftiest of my goals.”
                </h4>
                <h3>Kathryn Lampo</h3>
                <p>2017 Finalist</p>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Related Alumni Profiles */}
    <Section className="related-profiles">
      <Container>
        <Row>
          <Column fullwidth>
            <h2>Related Alumni</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="alumni__bio box-shadow hover imagezoom">
              <Image filename="Kaien-Yang-Alumni-Card.jpg" />
              <div className="p-1.5">
                <span>2016 Finalist</span>
                <h3>Kaien Yang</h3>
                <p>
                  Kaien is a sophomore at Standford University majoring in
                  Computer Science with an AI track. He works with robots and
                  machine learning, and plays for Stanford’s club tennis team.
                </p>
                <Link to="../kaien-yang" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="alumni__bio box-shadow hover imagezoom">
              <Image filename="Nicholas-LaJoie-Alumni-Card.jpg" />
              <div className="p-1.5">
                <span>2009 Finalist</span>
                <h3>Nicholas LaJoie</h3>
                <p>
                  Nicholas studied Computer Engineering in college and is
                  currently pursuing a career in technology. He plays the drums
                  and has worked in filmmaking.
                </p>
                <Link to="../nicholas-lajoie" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Faraz-Tamboli-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2019 Finalist</span>
                <h3>Faraz Tamboli</h3>
                <p>
                  Since participating in the 2019 Challenge, Faraz has continued
                  to improve and enhance his entry project, Talk Motion. Faraz
                  is currently in 9th grade and enjoys reading fantasy stories,
                  making his own music and participating in Mock Trial Club.
                </p>
                <Link to="../faraz-tamboli" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default KatieLampo;
